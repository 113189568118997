/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Button, Title, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"ROKLEN10let"}>
        <SiteHeader set="m5rwthlnyrk" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"4h9gdwxa7wm"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":812}} srcSet={"https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=350x_.png 350w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=660x_.png 660w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=860x_.png 860w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"backgroundColor":"rgba(0, 0, 0, 1)","marginTop":0,"paddingTop":0,"paddingBottom":0}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Button className="btn-box fs--16" style={{"maxWidth":341}} content={"Přidat událost do kalendáře!"} url={"https://calendar.google.com/calendar/ical/eea2f7ecc54d25a0e93fb537dc5618f7ad7296c640c81d2f45fa767b491a2a99%40group.calendar.google.com/public/basic.ics"} href={"https://calendar.google.com/calendar/ical/eea2f7ecc54d25a0e93fb537dc5618f7ad7296c640c81d2f45fa767b491a2a99%40group.calendar.google.com/public/basic.ics"}>
              </Button>

              <Title className="title-box fs--22" content={"<span style=\"color: var(--color-custom-2);\">Těšíme se na Vás!</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)","marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --right el--1" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":"","paddingTop":13}}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39934/ab02196e8ffb49b5838ec1be45127715_s=350x_.PNG 350w"} alt={""} src={"https://cdn.swbpg.com/t/39934/ab02196e8ffb49b5838ec1be45127715_s=350x_.PNG"}>
              </Image>

              <Subtitle className="subtitle-box fs--12 lh--16" content={"<span style=\"color: var(--black); font-weight: bold;\">Václavské Nám. 838/9, Praha 1, 110 00<br>roklen10@roklen.cz&nbsp;<br>+420 236 071 600</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}